.legend-dropdown {
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    font-size: smaller;
}

.dropdown {
    color: black;
    display: block;
    width: 100%;
    height: max-content;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;

}

.grid-container {
    display: grid;
    grid-template-columns: 40% 30% 15% 15%;
    grid-template-rows: 40px;
    align-items: center;
}

.helpicon {
    height: 15px;
    display: inline;
}

.textinputdropdown {
    height: max-content;
    margin-left: 10px;
    align-items: center;
}

.days {
    height: max-content;
    align-items: center;
    vertical-align: top;
}