.tooltip {
  position: relative;
  display: inline-block;
}

.tooltip .tooltiptext {
  visibility: hidden;
  width: 600px;
  height: 200px;
  background-color: rgb(191, 191, 191);
  color: black;
  text-align: left;
  border: 1px solid black;
  padding: 10px;


  position: absolute;
  z-index: 1;
  opacity: 0;
  transition: opacity 0.5s;
  overflow-y: scroll;
}

.tooltip:hover .tooltiptext {
  visibility: visible;
  font-size: 1rem;
  max-width: 20rem;
  transform: translateY(-50%);
  opacity: 1;
}

.days {
  vertical-align: middle;
}
