button {
  transition: all .5s ease;
  color: rgba(68, 65, 65, 0.963);
  border: 3px solid white;
  font-family: 'Montserrat', sans-serif;
  text-transform: uppercase;
  text-align: center;
  line-height: 1;
  background-color: rgb(188, 182, 182);
  padding: 5px;
  outline: none;
  width: 100px;
  height: 40px;
  border-radius: 4px;
}

button:hover {
  color: rgba(68, 65, 65, 0.963);
  background-color: rgb(127, 120, 120);
}