
.IEEM-icon{
    height: 90px;
	position: relative;
    top: 5;
    left: 10;
}

.HKA-icon{
    height: 50px;
	position: absolute;
    top: 30;
    right: 20;
}

h1{
    font-family: Arial, Helvetica, sans-serif;
}

.tooltip {
    position: relative;
    display: inline-block;
    border-bottom: 1px dotted black;
  }

  .tooltip .tooltiptext {
    visibility: hidden;
    width: 120px;
    background-color: black;
    color: #fff;
    text-align: center;
    border-radius: 6px;
    padding: 5px 0;

    /* Position the tooltip */
    position: absolute;
    z-index: 1;
  }

  .tooltip:hover .tooltiptext {
    visibility: visible;
  }
