.visualization {
    color: black;
    background-color: white;
}

.GPV-legend {
    font-size: x-large;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}

div {
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}

.plot {
    width: 95%;
    margin-top: 5px;

}

.ResultTable {
    width: 100%;
}

.td-text {
    width: 45%;
}

.td-value {
    text-align: left;
    width: auto;
}